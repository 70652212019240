import REQUEST from '@/api/http'
import { getRightTopModelList } from '@/service/RightTopModelListGet'
import { MODEL_VALUE_IMG_ANALYSIS, MODEL_VALUE_VOICE_INPUT, MODEL_VALUE_WORD__VOICE, MODEL_VALUE_DRAW } from '@/service/constant'
import Vue from 'vue'
import Vuex from 'vuex'


export default {
    state: ()=>{
        return {
            curUseToolId:null,//当前正在使用的工具
            curUseToolName:"",
            curUseToolIcon:"",
            curModelType:"",
            curUseTool:null,//当前使用的工具

             /**
              * 在没有粘贴内容的情况下，选择工具，就赋值给这个，发送后，这个就设为空
                在有粘贴内容的情况下，选择工具，就赋值给这个，发送后，这个就设为空
                发送的时候，要把这个的icon和appName和appId赋值给对话记录
              */
            isSeleteTool:null,

            isPreView:false, //当前是否是预览
            isOwnerView:false,//应用创建者自己访问
            normalUseView:false,//正式使用
            isFirstPreview:false,//一进入应用编辑页的时候，自动预览的时候，在这个设为true，再点击预览按钮后，这个设为false

            appVersionId:null, //访问自己的应用的时候用的
            appDraftId:null, //草稿id，预览的时候用的


            canOusideClose:true, //工具弹窗是否允许点击外部关闭

            curWrittedValue:null,//当前使用的工具的已填写的变量

            isMyPromptShowDialog:false, //在匹配弹窗-我的提示词，删除提示词的时候会弹出确认弹窗，点确认提示框，匹配弹窗会消失，所以用这个参数

            showisAppParameSetDialog:false,//是否在显示参数输入框

        }
    },
    mutations:{
        SET_curUseToolId: (state, data) => {
            state.curUseToolId = data
        },
        SET_curUseToolName: (state, data) => {
            state.curUseToolName = data
        },
        SET_curUseToolIcon: (state, data) => {
            state.curUseToolIcon = data
        },
        SET_curModelType: (state, data) => {
            state.curModelType = data
        },
        SET_curUseTool: (state, data) => {
            state.curUseTool = data
        },
        SET_isSeleteTool: (state, data) => {
            state.isSeleteTool = data
        },
        SET_isPreView: (state, data) => {
            state.isPreView = data
        },
        SET_appVersionId: (state, data) => {
            state.appVersionId = data
        },
        SET_appDraftId: (state, data) => {
            state.appDraftId = data
        },
        SET_canOusideClose: (state, data) => {
            state.canOusideClose = data
        },
        SET_curWrittedValue: (state, data) => {
            state.curWrittedValue = data
        },
        SET_isMyPromptShowDialog: (state, data) => {
            state.isMyPromptShowDialog = data
        },
        SET_isOwnerView: (state, data) => {
            state.isOwnerView = data
        },
        SET_normalUseView: (state, data) => {
            state.normalUseView = data
        },
        SET_showisAppParameSetDialog: (state, data) => {
            state.showisAppParameSetDialog = data
        },
        SET_isFirstPreview: (state, data) => {
            state.isFirstPreview = data
        }

        
    },
    actions:{
        saveToWorkspace: async ({commit}, {appId})=>{
            await REQUEST.POST("addUsrApp", {type: 2, appId})
        },
        saveToCollectList: async ({commit}, {appId})=>{
            return await REQUEST.POST("addUsrApp", {type: 1, appId})
        },
        //取消收藏
        delCollect: async ({commit}, {usrAppId})=>{
            return await REQUEST.GET("deleteUsrApp", {id:usrAppId})
        },

        loadCurWrittedValue: async ({commit}, {logId, appId})=>{
            var param  ={}
            param.appId = appId
            param.logId = logId

            var res = await REQUEST.POST('GET_APP_PARAMS', param)

            if(res.data){
                commit('SET_curWrittedValue', res.data)
            }
        }

        
    },

    getters:{
        /**
         * 当前使用的工具是否有解释图片的权限
         * @param {*} state 
         */
        // curToolIsCanExplain:(state)=>{
        //     if(!state.curUseTool){
        //         return false
        //     }
        //     if(!state.curUseTool.hasOwnProperty('extensions')){
        //         return false
        //     }
        //     var n = state.curUseTool.extensions & 4//扩展功能（多值或），1 语音，2 画图，4 图片解释
        //     if(n == 0){
        //         return false
        //     }
        //     return true
        // },

        /**
         * 当前使用的工具是否有解释图片的权限
         * @param {*} state 
         */
        curToolIsCanExplain:(state)=>{
            if(!state.curUseTool){
                return false
            }
            var res = state.curUseTool.availableModels.find((val)=> val.modelValue == MODEL_VALUE_IMG_ANALYSIS)
            if(res){
                return true
            }
            return false
        },

        // /**
        //  * 当前使用的工具是否有语音输入的权限
        //  * @param {*} state 
        //  */
        // curToolIsCanRecordInput:(state)=>{
        //     if(!state.curUseTool){
        //         return false
        //     }
        //     if(!state.curUseTool.hasOwnProperty('extensions')){
        //         return false
        //     }
        //     var n = state.curUseTool.extensions & 1//扩展功能（多值或），1 语音，2 画图，4 图片解释
        //     if(n == 0){
        //         return false
        //     }
        //     return true
        // },



        /**
         * 当前使用的工具是否有语音输入的权限
         * @param {*} state 
         */
        curToolIsCanRecordInput:(state)=>{
            if(!state.curUseTool){
                return false
            }
            var res = state.curUseTool.availableModels.find((val)=> val.modelValue == MODEL_VALUE_VOICE_INPUT)
            if(res){
                return true
            }
            return false
        },



        /**
         * 当前使用的工具是否有文字转语音的权限
         * @param {*} state 
         */
        curToolIsCanWordToVoice:(state)=>{
            if(!state.curUseTool){
                return false
            }
            var res = state.curUseTool.availableModels.find((val)=> val.modelValue == MODEL_VALUE_WORD__VOICE)
            if(res){
                return true
            }
            return false
        },


        /**
         * 当前使用的工具是否有画图的权限
         * @param {*} state 
         */
        curToolIsCanDrawPic:(state)=>{
            if(!state.curUseTool){
                return false
            }
            var res = state.curUseTool.availableModels.find((val)=> val.modelValue == MODEL_VALUE_DRAW)
            if(res){
                return true
            }
            return false
        },
        



        curTool_talkModelList:(state)=>{
            var arr = getRightTopModelList(state.curUseTool)
            return arr


            // if(!state.curUseTool){
            //     return []
            // }
            // if(!state.curUseTool.hasOwnProperty('availableModels')){
            //     return []
            // }

            // var arr = []
            // state.curUseTool.availableModels.forEach((val)=>{
            //     if(val.modelValue == 1 || val.modelValue == 8 || val.modelValue == 128){
            //         // modelValue
            //         // name
            //         arr.push(val)
            //     }
            // })
            // return arr


        }
    }
}