
import { EventSourcePolyfill } from 'event-source-polyfill'
import { HTTP_URI } from '@/api/api'
import axios from 'axios'
import store from '@/store'
import SnowflakeId from "snowflake-id";
import REQUEST from '@/api/http';
import request from '../api/request.js'
import { getToken } from './login.js';

var eventSource
var sseCallback
var curLogId

export function sseListener(callback){
    sseCallback = callback
}

export function addSSE(logId, openCallback, beatTime){

    var receiveCount = 0

    curLogId = logId
    var param = {
        // 设置重连时间
        // heartbeatTimeout: 5 * 1000,
        // 添加token
        headers: {
          
          token:getToken(),
          'Access-Control-Allow-Origin': '*'
        }
    }
    if(beatTime){
        param.heartbeatTimeout = beatTime
    }
    eventSource = new EventSourcePolyfill(`${HTTP_URI.GETSSE}/${logId + '' + window.localStorage.getItem('murmur')}`, param)
      eventSource.onopen = e => {
        console.log('已建立SSE连接~', e)
      }
      eventSource.onmessage = e => {
        receiveCount++

        if(e.data == "OK"){
            console.log("收到ok，连接成功");
            if(openCallback && receiveCount == 1){
                openCallback()
            }
        }else{
            if(sseCallback&&e.data){
                sseCallback(e.data)
            }
        }
            
      }
      eventSource.onerror = e => {
        console.log('===连接onerror', e)
        // console.log("readyState = ", e.target.readyState)

        console.log("EventSource.CLOSED", EventSource.CLOSED)
        console.log("EventSource.CONNECTING", EventSource.CONNECTING)

        // eventSource.close()

        if (e.readyState == EventSource.CLOSED) {
          // this.$store.commit('SET_SCOKET', {scoketText: '断开连接', color: 'red'})
          console.log('SSE已经关闭')

        } else if (eventSource.readyState == EventSource.CONNECTING) {
            // this.$message.success('SSE正在重连')
            console.log('SSE正在重连')
            //重新设置token
            //   eventSource.headers = {
            //     token: getToken()
            //   }
        } else {
          console.log('error', e)
        }
      }

    // 监听close
    eventSource.addEventListener('close', event => {
        console.log('sse的close事件');
    });
}

export async function closeSSE(){
    eventSource.close()

    var closeRes = await request({
        url: HTTP_URI.CLOSESS + `${curLogId}${window.localStorage.getItem('murmur')}`,
        method: 'get',
        data: {},
        loading:false
    })

    console.log("调关闭连接接口返回", closeRes)

}

export async function stopMessage(){
    var res = await REQUEST.GET_PATH("STOP_OUTPUT", "/" + curLogId + "" + window.localStorage.getItem('murmur'))

}