<template>
  <div class="account_regWrap" :class="isXmrc?'alone':'public'">
    <el-form :model="regform"
      :rules="regrules"
      ref="regform"
      label-width="0px"
      class="demo-ruleForm">
      <el-form-item prop="name">
        <el-input
          prefix-icon="el-icon-user-solid"
          placeholder="请输入昵称"
          v-model="regform.name"></el-input>
      </el-form-item>
      <el-form-item prop="mobile">
        <el-input
          auto-complete="new-password"
          prefix-icon="el-icon-mobile-phone"
          placeholder="请输入手机号"
          v-model="regform.mobile">
        </el-input>
      </el-form-item>

      <el-form-item class="mobilCodeItem" prop="vcode">
        <el-input
          class="ipt"
          autoComplete="new-password"
          prefix-icon="el-icon-notebook-2"
          placeholder="请输入短信验证码"
          v-model="regform.vcode">
          
        </el-input>
        <div v-if="codeTime <= 0" type="primary" class="getMobilCodeBtn" @click="mobilCodeBtnClick">短信验证</div>
        <div v-else type="primary" class="getMobilCodeBtn getMobilCodeBtnGray">{{ codeTime }}</div>

      </el-form-item>


      <el-form-item
        prop="password">
        <el-input
        class="ipt"
          autoComplete="new-password"
          type="password"
          prefix-icon="el-icon-s-goods"
          placeholder="请输入密码"
          v-model="regform.password"></el-input>
      </el-form-item>

      <el-form-item
        prop="rePassword">
        <el-input
        class="ipt"
          autoComplete="new-password"
          type="password"
          prefix-icon="el-icon-s-goods"
          placeholder="重复输入密码"
          v-model="regform.rePassword"></el-input>
      </el-form-item>


      <el-form-item
        v-show="false"
        prop="browserFingerprint">
        <el-input
        
          prefix-icon="el-icon-s-goods"
          placeholder="请输入密码"
          v-model="regform.browserFingerprint"></el-input>
      </el-form-item>

      <el-form-item style="margin-bottom: 0;">
        <el-button type="primary" @click="reglogin" style="width:100%">注册</el-button>
      </el-form-item>
    </el-form>

    <div class="appInfoWrap">
        <el-checkbox v-model="checked"></el-checkbox>
        <span class="info-text">我已阅读并同意</span>
        <el-button type="text" @click="routingJump('userServices')">用户协议</el-button>
        <span class="info-text">&</span>
        <el-button type="text" @click="routingJump('privacy')">隐私政策</el-button>
    </div>

    <div class="bottomWrap" v-if="!backState">
        <el-button type="text" @click="$emit('chanePage')" v-if="!hideLoginBtn">已有账号，立即登录</el-button>
        <el-button type="text" @click="$emit('forgetPwd')">忘记密码</el-button>
    </div>

    <div class="bottomWrap" v-else>
        <el-button type="text" @click="eventTrigger('login')">已有账号，立即登录</el-button>
        <el-button type="text" @click="eventTrigger('pwd')">忘记密码</el-button>
    </div>
    <SlideVerify
      @success="success"
      v-if="showSlide">
    </SlideVerify>
  </div>
</template>

<script>
import SlideVerify from '@/components/SlideVerify/index.vue'
import {getQueryString, isTel, routerPush} from '../utils/index'
import { setToken } from '@/service/login'
import { mapState } from 'vuex'

export default {
  components: { SlideVerify },
  props:["openId", "hideLoginBtn", "backState"],
  data() {

    var checkMobil = (rule, value, callback)=>{
      if (!value) {
        return callback(new Error('手机号不能为空'));
      }

      var bool = isTel(value)
      if(bool){
        callback()
      }else{
        callback(new Error('手机号格式不正确'))
      }
    }

    var checPassword = (rule, value, callback)=>{
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.regform.rePassword !== '') {
          this.$refs.regform.validateField('rePassword');
        }
        callback();
      }
    }

    var checRePassword = (rule, value, callback)=>{

        //   console.log("value", value)
        //   console.log("this.regform.password", this.regform.password)

      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.regform.password) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    }
    return {
        isXmrc:false,
        checked: false,
        regform: {
            name: '',
            mobile: '',
            vcode:"",
            password: '',
            rePassword: "",
            browserFingerprint: ''
        },
        message: '',
        disabled: false,
        timer: null,
        showYzMode: false,
        showSlide: false,
        codeText: '获取验证码',
        regrules: {
            name: [{ required: true, message: '请输入昵称', trigger: 'blur' }],
            password: [{ validator:checPassword , trigger: 'blur' }],
            mobile: [
            // { required: true, message: '请输入手机号', trigger: 'blur' },
            { validator:checkMobil , trigger: 'blur' }
            ],
            vcode:[{ required: true, message: '请输入短信验证码', trigger: 'blur' }],
            rePassword:[{ validator:checRePassword , trigger: 'blur' }]
        },
        codeTime:false,//倒计时时间
        codeTimeTotal:60,//两次获取验证码时间间隔必须大于这个数
        intervalId:null
    }
  },
    computed:{
        ...mapState(['eventType']),

    },
  mounted() {
        let baseUrl = localStorage.getItem('baseUrl')
        if(baseUrl){
            this.isXmrc = baseUrl.includes("ai.xmgxrc.com")
        }else{
            this.isXmrc = location.href.includes("ai.xmgxrc.com")
        }
        this.regform.browserFingerprint = window.localStorage.getItem('murmur')
  },
  beforeDestroy(){
    clearInterval(this.intervalId)
  },
  methods: {
    eventTrigger(type){
        this.$store.commit('SET_eventType', type)
    },
    routingJump(path) {
        routerPush("/"+path)
    },
    reglogin() {
        if(this.checked){
            this.$refs.regform.validate(valid => {
                if (valid) {
                    if (this.showYzMode == true) {
                        this.showSlide = true
                    } else {

                        // var userCode = getQueryString("userCode")
                        var userCode = localStorage.getItem("userCode")
                        if(userCode){
                        this.regform.inviteCode = userCode
                        }

                        if(this.openId){
                        this.regform.fromUserName = this.openId
                        }
                        
                        this.$https('ACCOUNT', this.regform).then(res => {
                        if (res.status == 200) {
                            this.$message.success('注册成功！')

                            // if(this.openId){
                            this.$store.commit('SET_isXmrc', res.data.isXmrc)
                            
                            this.$store.commit('SET_TOKEN', res.data.token)
                            setToken(res.data.token)
                            this.$store.commit('SET_HAVE_USER_INF', false)
                            this.$store.dispatch('loadHomeData')

                            res.data.vipPackage.expiryTime = res.data.expiryTime;
                            if(res.data.vipPackage) {
                                
                            }

                            this.$emit("success")

                        }
                        })
                    }
                }
            })
        }else{
            this.$message.warning('您未阅读并同意隐私协议和用户服务协议')
        }
    },
    success(data) {
      this.showSlide = false
      this.$https('ACCOUNT', this.regform).then(res => {
        // console.log(res)
        if (res.status == 200) {
          this.$message.success('注册成功！')
          this.$emit('chanePage')
        }
      })
    },
    async mobilCodeBtnClick(){
      if(this.codeTime > 0){
        return
      }

      this.$refs.regform.validateField("mobile", async valid => {
        if (!valid) {
          // 发送验证码

          var res = await this.$https('GET_SEND_VCODE', {mobile: this.regform.mobile, type: 1})
          console.log("发送验证码返回", res)

          this.codeTime = this.codeTimeTotal
          this.intervalId = setInterval(() => {
            this.codeTime -= 1
            if(this.codeTime <= 0){
              clearInterval(this.intervalId)
            }
          }, 1000);

        }
      })
      
      

    }
  }
}
</script>

<style scoped lang="scss">
.public{}
.alone{
    padding: 20px !important;
    border-radius: 8px;
    background-color: rgba(218, 218, 218, 0.6);
}

.mobilCodeItem ::v-deep .el-form-item__content{
  display: flex;
  flex-direction: row;
}
.getMobilCodeBtn{
  margin-left: 5px;
  width: 100px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background: #45c084;
  color: #fff;
  font-size: 13px;
  border-radius: 4px;
  cursor: pointer;
}
.getMobilCodeBtnGray{
  background: #eeeeee;
  color: #707070;
  cursor: default;
}

.ipt {
  ::deep .el-input__inner {
    -webkit-text-security:disc!important;
  } 
}


.bottomWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 13px !important;
//   padding-top: 8px;
}
.account_regWrap{
    padding: 0;
}
.appInfoWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    // justify-content: center;
    padding-top: 8px;
    .info-text{
        font-size: 14px;
        color: #575757;
        margin: 0 5px;
    }
}
</style>