<!--
 * @Description:: 请补充填写
 * @Version: 1.0
 * @Author: smallWhite
 * @Date: 2023-03-21 21:29:37
 * @LastEditors: smallWhite
 * @LastEditTime: 2023-05-25 18:08:59
 * @FilePath: /AIGo.work/src/layout/login.vue
-->
<template>

  <div>
    <div v-if="!qrcodeFinish">
      <el-tabs class="topTabs" v-model="tabActiveName" @tab-click="tabClick" type="card" v-if="showWxQrcodeLogin">
        <el-tab-pane label="手机号登录" name="0"></el-tab-pane>
        <el-tab-pane label="微信登录" name="1"></el-tab-pane>
      </el-tabs>
      <vconsoleSwitch class="vconsoleSwitch"></vconsoleSwitch>

      <div class="loginWrap" :class="isXmrc?'alone':'public'" v-if="tabActiveName == 0">
        <el-form :model="form"
          :rules="rules"
          ref="ruleForm"
          label-position="top"
          label-width="0px"
          class="demo-ruleForm">
          <el-form-item
            prop="mobile">
            <el-input
              prefix-icon="el-icon-mobile-phone"
              placeholder="请输入手机号"
              v-model="form.mobile"></el-input>
          </el-form-item>
          <el-form-item
            prop="password">
            <el-input :type="type"
              prefix-icon="el-icon-s-goods"
              placeholder="请输入密码"
              v-model="form.password">
              <i slot="suffix"
                @click="type == 'password'? type ='text':type ='password'"
                class="el-input__icon el-icon-view"></i>
            </el-input>
          </el-form-item>
          <el-form-item
            style="margin-bottom: 0;">
            <el-button
              type="primary"
              @click="login"
              style="width:100%">登录</el-button>
          </el-form-item>
        </el-form>
        <div class="bottomWrap">
            <el-button
                @click="$emit('chanePage')"
                type="text">还没有账号？立即注册</el-button>
            <el-button
              @click="$emit('forgetPwd')"
              type="text">忘记密码</el-button>
        </div>
      </div>

      <div class="wxWrap" v-if="tabActiveName == 1">

        <div v-if="!isPhone">
            <img class="qrcode" :src="qrcode">
            <div class="wxTips">长按二维码 选择前往公众号“星舰AI平台” 即可登录</div>
        </div>

        <div v-if="isPhone">
            <div class="wxLoginBtn" @click="wxLoginBtnClick">点击微信登录</div>
        </div>        

      </div>
    </div>

    <afterQrcode v-if="qrcodeFinish" :openId="qrcode_openId" @chanePage="registOrBindFinish" :inviterId="inviterId" ref="afterQrcodeRef"></afterQrcode>

  </div>

</template>

<script>
import mailcodeVue from './reg.vue'
import {getQueryString, getQueryStrByUrl, routerPush} from '../utils/index'
import REQUEST from '@/api/http'
import { waitTimes, isMobile,isWx, cancalAnonymous} from '../utils/index'
import afterQrcode from "./afterQrcode.vue"
import { gzh_Qrcode_key, STORAGE_PRELOGIN_PAGE } from '@/service/constant'
import vconsoleSwitch from '../components/vconsoleSwitch.vue'
import { mapState } from 'vuex'
import { getToken, setToken } from '@/service/login'
import store from "@/store";

export default {
    components: { mailcodeVue , afterQrcode, vconsoleSwitch},
    data() {
        return {
            qrcodeFinish:false,//扫码后显示的界面的开关
            qrcode_openId:"",//扫码后得到的openId

            showPage: true,
            form: {
                mobile: '',
                password: ''
            },
            disabled: false,
            reg_type: 1,
            time: '',
            type: 'password',
            regs: 0,
            tabActiveName:0,
            qrcode:"",
            isGettingResult:false,//是否正在获取扫码登录的接口
            rules: {
                mobile: [{ required: true, message: '请输入手机号', trigger: 'change' }],
                password: [{ required: true, message: '请输入密码', trigger: 'change' }]
            },
            showWxQrcodeLogin:false,

            inviterId:null,

            testCount:0,

            isXmrc:false,
        }
    },
    mounted(){
        let baseUrl = localStorage.getItem('baseUrl')
        if(baseUrl){
            this.isXmrc = baseUrl.includes("ai.xmgxrc.com")
        }else{
            this.isXmrc = location.href.includes("ai.xmgxrc.com")
        }
        localStorage.setItem('isXmrc', this.isXmrc)
        let returnRouter = JSON.parse(JSON.stringify(this.$store.state.returnRouter))
        if(returnRouter&&(returnRouter.indexOf("/userServices") == 0||returnRouter.indexOf("/privacy") == 0)){
            this.$store.commit('SET_returnRouter', null)
            this.qrcodeFinish = true
            this.$nextTick(() => {
                this.$refs.afterQrcodeRef.canRegist()
            })
        }else{
            this.qrcodeFinish = false
        }
        this.showWxQrcodeLogin = false
        if(!this.isXmrc){
            if(isMobile()){
                if(isWx()){
                    this.showWxQrcodeLogin = true
                    this.tabActiveName = '1'
                }
            }else{
                this.showWxQrcodeLogin = true
                this.tabActiveName = '1'
                this.tabClick()
            }
        }
    },
    computed:{

        ...mapState(['afterQrcodeShow', 'loginVisible','eventType']),
        isPhone(){
            return isMobile()
        },
        eventType() {
            return store.state.eventType
        },

    },
    watch: {
        eventType: {
            handler(newVal, oldVal) {
                if(newVal=='login'){
                    this.qrcodeFinish = false
                }
                if(newVal=='pwd'){
                    this.$emit('forgetPwd')
                }
                this.$store.commit('SET_eventType', '')
            },
            deep: true,
        },
    },
    destroyed(){
        this.isGettingResult = false
    },
  
    methods: {
        login() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {

                    this.$https('LOGIN', this.form).then(res => {
                        // if(!res) {} else
                        if (res.status != 200) {
                        // this.$alert(res.message, '提示')
                        // this.$message.error(res.message)
                        } else {
                            this.$store.commit('SET_isXmrc', res.data.isXmrc)
                            this.$store.commit('SET_TOKEN', res.data.token)
                            setToken(res.data.token)

                            this.$store.commit('SET_HAVE_USER_INF', false)
                            
                            res.data.vipPackage.expiryTime = res.data.expiryTime;
                            if(res.data.vipPackage) {
                                window.localStorage.setItem('vipInfo', JSON.stringify(res.data.vipPackage))
                            }
                            this.$message.success('登录成功！')
                            
                            cancalAnonymous();
                            this.$emit("success")

                        }
                    })
                }
            })
        },
        reglogin(data) {
            this.$https('REGISTER', data).then(res => {
                if (res.status == 200) {
                    window.localStorage.clear()
                    this.$store.commit('SET_OPEN', false)
                    this.$store.commit('SET_TOTAL', 0)
                    this.$store.commit('SET_USERINFO', {})
                    this.$store.commit('SET_AVATAR', '')
                    this.$store.commit('SET_NOTICE', '')
                    this.$store.commit('SET_MESSAGES', [])
                    this.$store.commit('SET_LS_TOTALS', 0)
                    this.$store.commit('SET_SCOKET', {})
                    this.$store.commit('SET_OPEN_MENU', false)
                    this.showPage = !this.showPage

                    setTimeout(() => {
                        this.$router.go(0)
                        this.$message.success('注册成功！')
                    }, 1000)
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        reglogins(data) {
            this.$https('CODEREG', data).then(res => {
                if (res.status == 200) {
                    this.$message.success('注册成功！')
                    this.showPage = !this.showPage
                    this.$alert(res.data, '提示')
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        regloginMail(data) {
            this.$https('EMAILREG', data).then(res => {
                if (res.status == 200) {
                    this.$message.success('注册成功！')
                    this.showPage = !this.showPage
                    this.$alert(res.data, '提示')
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        reg() {
            this.showPage = !this.showPage
            if (!this.showPage) {
                this.$https('REGTYPE', {}).then(res => {
                    this.regs = res.data
                })
            }
        },
        // 该代码片段没有问题，是一个用于判断当前设备是否为移动设备的函数。
        _isMobile() {
            let flag = navigator.userAgent.match(
                /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
            )
            return flag
        },
        async tabClick(){

            //   console.log("this.tabActiveName", this.tabActiveName)
            if(this.tabActiveName == 1){

                if(isWx()){
                    return
                }

                var userCode = localStorage.getItem("userCode")
                var param = {}
                if(userCode){
                    param.userCode = userCode
                }

                var headerData = {}
                if(userCode){
                    headerData.inviteCode = userCode
                }

                var res = await REQUEST.POST_HEADER('GET_TICKET', param, headerData)
                if(res.data){
                this.qrcode = res.data

                //二维码保存在本地，在路由守卫里判断有没有这个，有的话调GET_LOGIN_BY_WX2接口，
                //如果res.data为空表示没有进入公众号就跳转了，就删除本地gzh_Qrcode_key
                //如果res.data不为空表示有结果了，
                localStorage.setItem(gzh_Qrcode_key, res.data)
                this.getQrcodeResult()
                }
            }else{
                this.isGettingResult = false
            }

        },
        //获取扫码登录结果
        async getQrcodeResult(){
            this.isGettingResult = true
        
            var ticket = getQueryStrByUrl(this.qrcode, "ticket")
            
            while(this.isGettingResult){


                var res = await REQUEST.POST_QUERY('GET_LOGIN_BY_WX2', {ticket}, false)
                //res.data不为空表示有结果了，
                //res.data.userId > 0 表示已经已关注并绑定手机号了
                //res.data.userId <= 0 表示未关注或未绑定手机号

                if(res.data){

                    
                this.isGettingResult = false

                if(res.data.userId > 0){//在直接变成已登录状态
                    this.$store.commit('SET_isXmrc', res.data.isXmrc)
                    this.$store.commit('SET_TOKEN', res.data.token)
                    setToken(res.data.token)
                    this.$store.commit('SET_HAVE_USER_INF', false)

                    localStorage.removeItem(gzh_Qrcode_key)

                    res.data.vipPackage.expiryTime = res.data.expiryTime;
                    if(res.data.vipPackage) {
                    // window.localStorage.setItem('vipInfo', JSON.stringify(res.data.vipPackage))
                    }
                    // window.localStorage.setItem('userInfo', JSON.stringify(res.data))

                    cancalAnonymous()

                    this.$message.success('登录成功！') 
                    routerPush("/")
                    this.$emit('success')
                }else{
                    //显示注册或绑定手机号

                    this.qrcodeFinish = true
                    this.qrcode_openId = res.data.fromUserName
                    this.inviterId = res.data.inviterId

                }

                return
                }else{
                await waitTimes(2000)
                }
            }
        },

        regist(){
            this.qrcodeFinishRegist = true
            this.qrcodeFinishBindMobil = false
        },

        bindMobil(){
            this.qrcodeFinishRegist = false
            this.qrcodeFinishBindMobil = true
        },

        registOrBindFinish(){
            console.log('---------- login  success  ----------');
            this.$emit('success')

        },

        wxLoginBtnClick(){
            cancalAnonymous()
            routerPush("/WxLoginPage")
        },
        wxTestFun(){
            this.testCount ++
            if( this.testCount == 9){
                this.wxLoginBtnClick()
            }
        }

    }
}
</script>

<style lang="scss" scoped>
.loginWrap ::v-deep .el-input {
    font-size: 20px !important;
    .el-input__inner {
        height: 50px !important;
        line-height: 50px !important;
        font-size: 20px !important;
    }
}
.public{}
.alone{
    border-radius: 8px;
    background-color: rgba(218, 218, 218, 0.6);
    // filter: blur(0px) !important; /* 调整像素值以达到所需的模糊程度 */
    // -webkit-filter: blur(0px) !important; /* 为Safari和其他WebKit浏览器添加前缀 */
    // -moz-filter: blur(0px) !important; /* 为Firefox浏览器添加前缀 */
    // -ms-filter: blur(0px) !important; /* 为IE浏览器添加前缀 */
    // -o-filter: blur(0px) !important; /* 为Opera浏览器添加前缀 */
}
.loginWrap{
    padding: 25px 25px;
    border: 1px solid #E4E7ED;
    // border-top: none;
}

.el-form--label-top .el-form-item__label {
  padding: 0;
  line-height: 30px;
}
.el-input-group__append button.el-button,
.el-input-group__append div.el-select .el-input__inner,
.el-input-group__append div.el-select:hover .el-input__inner,
.el-input-group__prepend button.el-button,
.el-input-group__prepend div.el-select .el-input__inner,
.el-input-group__prepend div.el-select:hover .el-input__inner {
  background: #409eff;
  color: #fff;
}
.el-button--primary.is-disabled {
  background: rgb(158, 159, 161) !important;
  color: rgb(59, 56, 56) !important;
}

.qrcode{
  display: block;
  width: 180px;
  height: 180px;
  margin: 0 auto;
}
.wxTips{
  text-align: center;
  font-size: 13px;
  padding: 8px 0;
  color: #6C4EFF;
}

.bottomWrap{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 20px 0;
}

.vconsoleSwitch{
  height: 40px;
  position: absolute;
  left: 0;
  top: 0;
}
.wxLoginBtn{
    width: 200px;
    height: 60px;
    line-height: 60px;
    background: rgb(3, 219, 107);
    text-align: center;
    color: #fff;
    font-size: 15px;
    margin: 30px auto 0;
    border-radius: 10px;
}
.wxWrap{
    border: 1px solid #E4E7ED;
    border-top: none;
    padding: 25px;
    position: relative;
    .wxTest{
        width: 45px;
        height: 45px;
        background: #409eff;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
    }
}

.topTabs ::v-deep .el-tabs__header{
    margin: 0;
}

</style>