import REQUEST from '@/api/http'
import { formateDate } from '@/utils'
import Vue from 'vue'
import Vuex from 'vuex'

export default {
    state: ()=>{
        return {

            commentList:"",
            articleId:"",
            pageNumber:0,
            pageSize:10,
            isEnd:false

        }
    },
    mutations:{

        SET_isEnd: (state, data) => {
            state.isEnd = data
        },
        SET_commentList: (state, data) => {
            state.commentList = data
        },

        SET_articleId: (state, data) => {
            state.articleId = data
        },

        SET_pageNumber: (state, data) => {
            state.pageNumber = data
        },

        SET_pageSize: (state, data) => {
            state.pageSize = data
        }
        

        
    },
    actions:{
        loadComment: ({commit, state},{})=>{
            return new Promise(async (resolve, reject)=>{

                if(state.isEnd){
                    return
                }

                // var res = await REQUEST.POST2({
                //     businessType:"queryComment",
                //     datas:{
                //         articleId: state.articleId,
                //         pageNumber: state.pageNumber,
                //         pageSize: state.pageSize
                //     }
                // })
                
                var res = await REQUEST.POST("queryComment",{
                        articleId: state.articleId,
                        pageNumber: state.pageNumber,
                        pageSize: state.pageSize
                    }
                )
                if(res.status==200&&res.data.records.length>0){
                    res.data.records.forEach((val)=>{
                        val.showInput = false
                        val.inputText = ''
                    })
                }
                state.commentList = state.commentList.concat(res.data.records)
                commit('SET_commentList', state.commentList)
                if(res.data.records.length == 0){
                    commit('SET_isEnd', true)
                }
                resolve()
            })
        },

        replyLandlord:({commit, state, rootState }, {text, item})=>{
            return new Promise(async (resolve, reject)=>{
                var param = {
                    articleId:state.articleId,
                    content:{
                        text:text
                    }
                }
                param.refId = item.id
        
                var res = await REQUEST.POST2({
                    businessType:"addComment",
                    datas:param
                })


                resolve(res.data)
            })

        },
        delComment:({commit, state, rootState},{item})=>{
            return new Promise(async (resolve, reject)=>{
                var res = await REQUEST.GET('deleteComment', {commentId:item.id})
                resolve()

            })
        }




    }
}