<template>
    <div>

        <!-- <vconsoleSwitch class="vconsoleSwitch"></vconsoleSwitch>  -->
  
            <div class="header">
                <img class="topImg" :src="topImg">
                <div class="headerRight">
                    <div class="headerTitle">{{ topName }}</div>
                    <div class="headerDes">{{ summary }}</div>
                </div>
            </div>

            <div class="t1">我的会员套餐</div>
            <card v-if="curGoods" :curGoods="curGoods" :isCure="true" @renewClick="renewClick"></card>
            <div class="nullTitle" v-else>您当前没有套餐</div>

            <div class="t1" v-if="canUpgradGoods.length > 0">您还可以选择升级会员套餐</div>
            <card v-for="(item, index) in canUpgradGoods" :key="index" :curGoods="item" :isCure="false" @upgradClick="upgradClick(item)"></card>
            <Pay ref="PayRef" @success="paySuccess"></Pay>

    </div>

</template>
  
<script>
import { isMobile, isTel, routerPush } from '@/utils'
import Pay from '@/components/pay/Pay.vue';

import { mapState } from 'vuex'
import store from '@/store'
import router from '@/router'
import Botton from "@/components/button/Button.vue"
import REQUEST from '@/api/http'
import { permissionDictionary } from '@/service/dictionary'
import UploadAvatar from '@/components/uploadImg/UploadAvatar.vue'
import ChangePwd from '@/layout/changePwd.vue'
import card from './card.vue'
import VconsoleSwitch from '@/components/vconsoleSwitch.vue';

export default {
    components: {Botton, UploadAvatar, ChangePwd, card, Pay, VconsoleSwitch},
    data() {
        return {
            isOrg:false,
            baseInfo:null,
            topName:"",
            summary:"",
            topImg:"",
            goodsList:[],
            canUpgradGoods:[

            ],
            isMember:false,
            curGoods:null,//当前已有的套餐
            
        }
    },
    props:{
        visible:Boolean
    },
    async mounted(){

        // await this.start()
        
    },
    watch: {
        visible(){
            if(this.visible){
                
            }
        }
    },
    computed:{
        ...mapState(['scoket', 'openMenu', 'total', 'userInfo', 'avatar', 'notice', 'gpt3Total', 'gpt4Total', 'loginVisible', 'haveUserInfo', , 'nickName', 'afterQrcodeShow', 'topInviteBtnShow']),
        width(){
            if(isMobile()){
                return "90%"
            }
            return "500px"
        },
        
    },
    methods:{
        init(){
            this.start()
        },
        async start(){
            if(location.href.indexOf("/starship/") > 0 || location.href.indexOf("/ss/") > 0 ||  location.href.indexOf("/MenberVipPage/") > 0){
                this.isOrg = true
                await this.loadBaseInfo()
                this.$nextTick(()=>{
                    // console.log("this.baseInfo.id", this.baseInfo.id);
                    this.$refs.PayRef.initMounted(this.baseInfo.id)
                })

                this.topName = this.baseInfo.name
                this.topImg=this.baseInfo.icon
                this.summary=this.baseInfo.summary

                await this.loadMemberInfo()

                this.loadGoodsInfo()
            }
        },
        async loadBaseInfo(){
            var res = await this.$https.get("getOrganizationByTag", {tag:this.$route.params.tag})
            this.baseInfo = res.data
        },
        handleClose(){
            this.$emit('update:visible', false)
        },
        //获取该组织的商品信息
        async loadGoodsInfo(){
            
            try{
                var res = await REQUEST.POST2({
                    businessType:'queryGoods',
                    datas:{goodsType:5, pageNumber:1, pageSize:10},
                    query:{orgId : this.baseInfo.id}
                })
                // console.log("获取组织的商品信息", res);
                if(res.data.records.length > 0){
                    res.data.records.forEach((val)=>{
                        val.upgradePrice = '0'
                        this.countInfoSet(val.goodsOrgMemberVip)
                    })
                    // console.log("res.data.records", res.data.records);
                    this.goodsList = res.data.records
                    if(this.curGoods){
                        this.canUpgradGoods = res.data.records.filter((val)=>{
                            return val.goodsOrgMemberVip.type > this.curGoods.goodsOrgMemberVip.type
                        })
                    }else{
                        this.canUpgradGoods = res.data.records
                    }
                    await this.setAllUpgradGoodsShowPrice()
                }

            }catch(err){

            }
            
        },
        /**
        * 获取组织会员信息，判断当前用户是不是组织会员
        */
        async loadMemberInfo(){
            try{
                var res = await this.$https.get("getOrgMemberGoods", {goodsType:5, orgId:this.baseInfo.id})
                if(res?.data?.goodsOrgMemberVip){
                    this.isMember = true
                    this.curGoods = res?.data
                    this.countInfoSet(this.curGoods.goodsOrgMemberVip)
                }else{
                    this.curGoods = null
                    this.isMember = false
                }

            }catch(e){

            }
        },

        countInfoSet(goodsOrgMemberVip){
            var countList = []
            for(let key in goodsOrgMemberVip.models){
                countList.push(goodsOrgMemberVip.models[key])
            }
            goodsOrgMemberVip.countList = countList
        },
        paySuccess(){

        },
        //点击续费按钮
        renewClick(){
            this.$refs.PayRef.startPay(this.curGoods.goodsId,  1, this.baseInfo.id)
        },
        //点击升级按钮
        upgradClick(item){
            //免费版和体验版不用传refId
            if(this.curGoods.goodsOrgMemberVip.type != 0 && this.curGoods.goodsOrgMemberVip.type != 1){
                this.$refs.PayRef.setRefId(this.curGoods.id)
            }
            this.$refs.PayRef.startPay(item.id,  1, this.baseInfo.id)

        },

        async getUpgradePrice(goodsId, oldUsrGoodsId){
            var res = await this.$https.get('getUpgradePriceV2', {goodsId:goodsId, oldUsrGoodsId:oldUsrGoodsId, orgId:this.baseInfo.id})
            
            return ({upgradePrice:res.data.upgradePrice,buyType:res.data.buyType})
        },

        //获取所有可升级套餐的差价
        async setAllUpgradGoodsShowPrice(){
            for(var i = 0; i < this.canUpgradGoods.length; i++){
                if(this.curGoods){
                    var upgradePriceData = await this.getUpgradePrice(this.canUpgradGoods[i].id, this.curGoods.id)
                    this.canUpgradGoods[i].upgradePrice = upgradePriceData.upgradePrice
                    this.canUpgradGoods[i].buyType = upgradePriceData.buyType
                }else{
                    this.canUpgradGoods[i].upgradePrice = this.canUpgradGoods[i].goodsOrgMemberVip.realPrice
                    this.canUpgradGoods[i].buyType = 1
                }
            }
            this.$forceUpdate();
        }

    }
}
</script>
  
<style scoped lang="scss">

.infoWrap{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 28px 0;
    .infoTitle{
        color: #606266;
        padding-right: 16px;
        width: 109px;
        text-align: right;
        
    }
    .avatar{
        width: 60px;
        height: 60px;
        border-radius: 60px;
    }
    .infoRight{
        color: #666;
        padding-right: 5px;
    }
}


.footerWrap{
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 40px;
}
.sureBtn{
    margin-left: 60px;
}
.t1{
    padding: 16px 0 8px;
    font-size: 16px;
    color: #363636;
}

.header{
    padding: 20px 0px 20px 0px;
    display: flex;
    flex-direction: row;
    position: relative;
    .topImg{
        width: 70px;
        height: 70px;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .headerRight{
        flex-grow: 1;
        padding-left: 12px;
    }
    .headerTitle{
        font-size: 18px;
        color: #444;
    }
    .headerDes{
        font-size: 12px;
        color: #a3a3a3;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* 设置行数，这里示例设置为2行 */
        line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 22px;
        padding-top: 6px;
    }
}

.vconsoleSwitch{
    position: absolute;
    right: 0;
    top: 0;
}
.nullTitle{
    padding: 20px 0;
    text-align: center;
    color: #999;
}

@media screen and (max-width: 900px) {

}

</style>