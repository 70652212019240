import REQUEST from "@/api/http";
import { changeURLArg, isAnonymous, isWx } from "@/utils";
import wx from 'weixin-js-sdk'; // 引入wxjs
import { getToken } from "./login";

//https://static.aigo.work/img/2023-11-2/wxShare.jpg

export function wxShareDefault(url, title){
    wxShare(url, 'https://static.aigo.work/img/2023-11-2/wxShare.jpg', title, 'aigo提示词优化技术,帮助用户高效驱动AI，获得更好的输出。Aigo帮助企业做AI转型升级，企业AI化，降本增效！')
}

export async function wxShare(url, icon, title, desc){
    // console.log("======wxShare参数======");
    // console.log('url', url);
    // console.log('icon', icon);
    // console.log('title', title);
    // console.log('desc', desc);

    if( !isWx() ){
        return
    }

    if(!getToken()){
        return
    }
    debugger
    var link = url

    if(isAnonymous()){//匿名用户不要获取用户信息

    }else{
        var res2 = await REQUEST.POST('GET_USERINFO', {})
        var userCode = res2.data.userCode
        link = changeURLArg(url, "userCode", userCode)
    }
    
    // var link = url

    // console.log('link',link);
    
    var res = await REQUEST.GET("createJsapiSignature", {url:url})

    wx.config({
        // debug: true,
        appId: res.data.appId,  // appID 公众号的唯一标识
        timestamp: res.data.timestamp, // 生成签名的时间戳
        nonceStr: res.data.nonceStr, //  生成签名的随机串
        signature: res.data.signature, // 生成的签名
        jsApiList: [
          'updateAppMessageShareData', // 分享到朋友
          'updateTimelineShareData', // 分享到朋友圈
        ]
    });

    wx.ready(function () {   //需在用户可能点击分享按钮前就先调用
        console.log(" ======== wx.ready =========");

        wx.updateAppMessageShareData({
          title: title, // 分享标题
          desc: desc, // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: icon, // 分享图标
          success: function () {
            // 设置成功
            console.log('========= updateAppMessageShareData ==========  设置成功');
          }
        })



        wx.updateTimelineShareData({ 
            title: title, // 分享标题
            link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: icon, // 分享图标
            success: function () {
                // 设置成功
                console.log('========= updateTimelineShareData ==========  设置成功');
            }
        })


    }); 
      
    wx.error(function(res){
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
        console.log("=====wx.config error=====", res);
    });
    

}