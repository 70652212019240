export function getRightTopModelList(appObject){

    if(!appObject){
        return []
    }
    if(!appObject.hasOwnProperty('availableModels')){
        return []
    }

    var arr = []
    appObject.availableModels.forEach((val)=>{
        // if(val.modelValue == 1 || val.modelValue == 8 || val.modelValue == 128 
        //     || val.modelValue == 256 || val.modelValue == 512 || val.modelValue == 1024){
        //     // modelValue
        //     // name
        //     arr.push(val)
        // }
        if(val.modelValue == 128 || val.modelGroup==1){
            arr.push(val)
        }
    })
    return arr


}